<template>
  <v-list-item class="mb-0 justify-space-between pl-3">
    <v-list-item-avatar>
      <v-img :src="require('@/assets/user1.jpg')" />
    </v-list-item-avatar>

    <v-list-item-content class="pl-2">
      <v-list-item-title class="text-h4 mt-2 pt-2">
        <p>{{ $store.state.authUser.user || "User" }}</p>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "DefaultAccountSettings",

  data: () => ({}),
};
</script>
